import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from '@react-hookz/web';
import { Typography } from '@mui/material';

import { Column, Grid } from 'components/grid/grid.component';
import { Profile } from 'components/profile';
import { Spinner } from 'components/spinner/spinner.component';
import { Tag } from 'components/tag/tag.component';
import { AdType } from 'constants/ad-type.enum';
import { DeadlineType, DeadlineTypeLabels } from 'constants/deadline-type.enum';
import { PriceType } from 'constants/price-type.enum';
import { Ad } from 'interfaces/ad.interface';
import { Card } from '../../components/card/card.component';
import style from './ad-page.module.scss';
import { useAdsStore } from 'services/ads.service';
import theme from 'styles/theme.module.scss';
import { User } from 'interfaces/user.interface';
import { useModalsStore, useUserStore } from 'stores';
import profileSrc from '../../assets/illustrations/avatar.png';

interface AdPageProps {
  data?: Ad;
  loaded?: boolean;
  preview?: boolean;
}

export const AdPage: React.FC<AdPageProps> = (props) => {
  const { data, loaded = true, preview = false } = props;
  // Fixa rad under!!!
  const [_, user] = useUserStore((state) => [state.setUser, state.user]); // eslint-disable-line
  const owner = user && user?.id === data?.userId;

  const navigate = useNavigate();

  const setShowDeleteAd = useModalsStore((state) => state.setShowDeleteAd);
  const handleDelete = useCallback(() => {
    setShowDeleteAd(true);
  }, [setShowDeleteAd]);

  const lines = data?.description.split('\n');

  const [showScrollLeft, setShowScrollLeft] = useState(false);
  const [showScrollRight, setShowScrollRight] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLParagraphElement>(null);

  const [clearFilter, setFilter] = useAdsStore((state) => [state.clearFilter, state.setFilter]);

  const isXs = useMediaQuery(`screen and (max-width:${theme['breakpoint-xs']})`);

  useEffect(() => {
    if (infoRef.current) {
      setShowReadMore(infoRef.current.offsetHeight < infoRef.current.scrollHeight && !readMore);
    }

    // Fix to make the above work on first load
    if (!firstLoaded) {
      setTimeout(() => {
        setFirstLoaded(true);
      }, 200);
    }
  }, [firstLoaded, infoRef, readMore]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      (scrollContainerRef.current as HTMLDivElement).scrollBy({
        left: 350,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      (scrollContainerRef.current as HTMLDivElement).scrollBy({
        left: -350,
        behavior: 'smooth',
      });
    }
  };

  const updateScrollButtons = () => {
    if (!scrollContainerRef.current) return;

    const element = scrollContainerRef.current;
    const tolerance = 1; // a small tolerance value

    setShowScrollLeft(element.scrollLeft > 0);
    setShowScrollRight(element.scrollLeft < element.scrollWidth - element.clientWidth - tolerance);
  };

  useEffect(() => {
    const element = scrollContainerRef.current;

    if (!element) return;

    const handleScrollEvent = () => {
      updateScrollButtons();
    };

    element.addEventListener('scroll', handleScrollEvent);

    // Clean up event listener when the component is unmounted
    return () => element.removeEventListener('scroll', handleScrollEvent);
  }, []);

  const handleCatClick = (catid: number) => {
    if (!preview) {
      clearFilter();
      setFilter({ categories: [catid], type: data?.type });
      navigate('/search');
    }
  };

  const getMemberSince = (user: User) => {
    const createdAt = new Date(user.createdAt);
    return `${createdAt.toLocaleString('sv-SE', { month: 'short' }).replace('.', '')} ${createdAt.toLocaleString(
      'sv-SE',
      {
        year: 'numeric',
      }
    )}`;
  };

  return (
    <Grid>
      {!loaded || !data ? (
        <Column span={12}>
          <Spinner size="large" />
        </Column>
      ) : (
        <>
          <Column span={[8, 8, 12, 4]} className={style.main}>
            <h1>{data.headline}</h1>
            {isXs && (
              <Link to={`/profile/${data?.user.id}`} className={style.mobileprofile}>
                <div className={style.avatar}>
                  <img src={data.user.avatar?.fullUrl || profileSrc} alt="Bild på användaren" />
                </div>
                <div className={style.profileinfo}>
                  <h2 className="heading-small">{data.user.publicName}</h2>
                  <span>Medlem sedan {getMemberSince(data.user)}</span>
                </div>
              </Link>
            )}
            <div className={style.categorycontainer}>
              {data?.categories.map((cat, index) => (
                <Tag
                  key={`cat-${index}-${cat.id}`}
                  color={data.type === AdType.Request ? 'red' : 'blue'}
                  onClick={preview ? undefined : () => handleCatClick(cat.id)}
                >
                  {cat.label}
                </Tag>
              ))}
            </div>
            <div className={style.imageScrollContainer}>
              {showScrollLeft && (
                <NavigateBeforeOutlinedIcon onClick={handleScrollLeft} className={style.scrollbuttonleft} />
              )}

              <div className={style.imagecontainer} ref={scrollContainerRef}>
                {data.images.map((image, index) => (
                  <img key={`img-${image.name}`} src={image.fullUrl} alt={`Uploaded file ${index + 1}`} />
                ))}
              </div>

              {showScrollRight && <NavigateNextOutlinedIcon onClick={handleScroll} className={style.scrollbutton} />}
            </div>

            <h2>{data.type === AdType.Request ? 'Om förfrågan' : 'Om tjänsten'}</h2>

            <div className={classNames(style.aboutText, { [style.closed]: !readMore })} ref={infoRef}>
              {
                lines?.map((line, index) => (
                  line ? (
                    <Typography key={`line-${index}`} style={{ wordWrap: "break-word" }}>
                      {line}
                    </Typography>
                  ) : <br key={`line-${index}`}/>
                ))
              }
              <div className={style.readmore}>
                {isXs && showReadMore && (
                  <button className="inverted iconLeft darkblue" onClick={() => setReadMore(true)}>
                    <ChevronRightIcon /> Läs mer
                  </button>
                )}
                {isXs && readMore && (
                  <button className="inverted iconLeft darkblue" onClick={() => setReadMore(false)}>
                    <ExpandLessIcon /> Visa mindre
                  </button>
                )}
              </div>
            </div>
            {owner && !preview ? (
              <div className={style.editBtnsContainer}>
                <button className="primary large" onClick={handleDelete}>
                  <DeleteOutlineIcon /> Ta bort
                </button>
              </div>
            ) : null}
          </Column>

          <Column span={[4, 4, 12, 4]} flex="column" className={style.aside}>
            <Card className={style.smallcard}>
              <ul className={style.infolist}>
                <li>
                  <span className="text-large">{data.type === AdType.Service ? 'Pris' : 'Budget'}</span>
                  <span className="text-large bold">
                    {data?.otherCosts || data?.travelCost ? 'från ' : ''}
                    {data.priceType === PriceType.Fixed ? `${data.fixedPrice}kr` : `${data.hourlyPrice}kr/h`}
                  </span>
                </li>
                {data.type === AdType.Service && data.travelCost && (
                  <li>
                    <span className="text-large">Ev. reskostnad</span>
                    <span className="text-large bold">{data.travelCost}kr/mil</span>
                  </li>
                )}
                {data.type === AdType.Service && data.otherCosts && (
                  <li className={style.fullcolumn}>
                    <span className={'text-small bold'}>Övriga kostnader kan tillkomma:</span>
                    <span className="text-small">{data.otherCosts}</span>
                  </li>
                )}
                {data.type === AdType.Request && data.deadlineType && (
                  <li>
                    <span className="text-large">Deadline</span>
                    <span className="text-large bold">
                      {data.deadlineType === DeadlineType.Date && data.deadline
                        ? new Date(data.deadline).toLocaleDateString('sv-SE')
                        : DeadlineTypeLabels[data.deadlineType]}
                    </span>
                  </li>
                )}
                {data.type === AdType.Service && <hr />}
                <li>
                  <span className="text-large">Plats</span>
                  <span className="text-large bold">{data.place?.label}</span>
                </li>
              </ul>

              <hr />
              {preview ? (
                <button
                  disabled
                  className={classNames('button iconLeft', data.type === AdType.Request ? 'red' : 'primary')}
                >
                  <EmailOutlinedIcon />
                  Kontakta
                </button>
              ) : (
                <>
                  <a
                    className={classNames('button iconLeft', data.type === AdType.Request ? 'red' : 'primary')}
                    href={`mailto:${data.user?.answerEmail ?? data.user?.email}`}
                  >
                    <EmailOutlinedIcon />
                    Kontakta
                  </a>
                  {data.user?.answerPhone && (
                    <a
                      className={classNames('button iconLeft', data.type === AdType.Request ? 'red' : 'primary')}
                      href={`tel:${data.user?.answerPhone}`}
                    >
                      <PhoneIcon />
                      Ring
                    </a>
                  )}
                </>
              )}
            </Card>
            {!isXs ? (
              <Profile user={data.user}>
                {data.user.svipper &&
                  (preview ? (
                    <button disabled className="button outlined darkblue">
                      Se profil
                    </button>
                  ) : (
                    <Link to={`/profile/${data?.user.id}`} className="button outlined darkblue">
                      Se profil
                    </Link>
                  ))}
              </Profile>
            ) : (
              <></>
              // <div className={style.mobilecontact}>
              //   {!preview && (
              //     <a href={`mailto:${data.user?.answerEmail ?? data.user?.email}`} className="button bullet xlarge solid primary">
              //       <EmailOutlinedIcon />
              //     </a>
              //   )}
              // </div>
            )}
          </Column>
        </>
      )}
    </Grid>
  );
};
