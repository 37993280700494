import { Card } from 'components/card/card.component';
import { Column, Grid } from 'components/grid/grid.component';
import { Profile } from 'components/profile/profile.component';
import { SvipperBox } from 'components/svipperbox/svipperbox.component';
import { User } from 'interfaces/user.interface';
import { Main } from 'layouts/main/main.component';
import { ProfilePageAds } from './components/ads/profile-page-ads.component';
import { AdType } from 'constants/ad-type.enum';
import { ProfilePagePresentation } from './components/presentation/profile-page-presentation.component';
import style from './profile-page.module.scss';
import { Spinner } from 'components/spinner/spinner.component';

import { Link } from 'react-router-dom';
import { EditOutlined } from '@mui/icons-material';

interface ProfilePageProps {
  user?: User;
  loaded?: boolean;
  edit?: boolean;
}

export const ProfilePage: React.FC<ProfilePageProps> = ({ user, edit = false, loaded = true }) => {
  return (
    <Main>
      <Grid>
        {!user || !loaded ? (
          <Column span={12}>
            <Spinner size="large" />
          </Column>
        ) : (
          <>
            <Column span={[4, 4, 12, 4]}>
              <Profile user={user} allowEdit={edit} />
            </Column>
            <Column span={[8, 8, 12, 4]}>
              <Grid>
                {edit && !user.svipper ? (
                  <Column span={12}>
                    <Card flexDirection="row" className={style.box}>
                      <SvipperBox />
                    </Card>
                  </Column>
                ) : user.svipper ? (
                  <>
                    <Column span={12}>
                      <ProfilePagePresentation user={user} canEdit={edit} />
                    </Column>
                    <Column span={12}>
                      <ProfilePageAds user={user} type={AdType.Service} />
                    </Column>
                  </>
                ) : (
                  <></>
                )}
                <Column span={12}>
                  <ProfilePageAds user={user} type={AdType.Request}/>
                  {edit && (
                    <div className={style.buttonwrapper}>
                      <Link to="/search" className="button outlined blue">
                        Se alla inlägg
                      </Link>
                      <Link to="/create-ad" className="button solid red iconLeft">
                        <EditOutlined />
                        Skapa inlägg
                      </Link>
                    </div>
                    )}
                </Column>
              </Grid>
            </Column>
          </>
        )}
      </Grid>
    </Main>
  );
};
