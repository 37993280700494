import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import classNames from 'classnames';
import { HTMLProps, ReactNode, useState } from 'react';
import { updateUser } from 'services/user.service';
import { ReactComponent as EditIcon } from '../../assets/icons/icon-edit.svg';
import profileSrc from '../../assets/illustrations/avatar.png';
import { User } from '../../interfaces/user.interface';
import { useUserStore } from '../../stores';
import { Card } from '../card/card.component';
import { EditProfile } from './edit-profile';
import style from './profile.module.scss';

interface ProfileProps extends HTMLProps<HTMLDivElement> {
  user: User;
  children?: ReactNode;
  allowEdit?: boolean;
}

const Profile = (props: ProfileProps) => {
  const { user, children, allowEdit = false } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const myUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const canEdit = myUser && myUser.id === user.id;

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      updateUser({ id: myUser?.id, headline: myUser?.headline, presentation: myUser?.presentation, avatar: file }).then(
        (res) => {
          if (res) {
            setUser(res);
          }
        }
      );
    }
  };

  const createdAt = new Date(user.createdAt);
  const memberSince = `${createdAt
    .toLocaleString('sv-SE', { month: 'short' })
    .replace('.', '')} ${createdAt.toLocaleString('sv-SE', {
    year: 'numeric',
  })}`;

  return isEdit ? (
    <EditProfile onEditEnd={() => setIsEdit(false)} />
  ) : (
    <Card className={style.container}>
      <div className={style.centerBox}>
        <div className={style.profileImgBox}>
          <img src={user?.avatar?.fullUrl || profileSrc} alt="" className={style.profileImg} />

          {canEdit && allowEdit && (
            <>
              <input
                type="file"
                id="profileImage"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImgChange}
              />
              <label htmlFor="profileImage" className={`button bullet darkblue inverted ${style.profileImgButton}`}>
                <AddAPhotoOutlinedIcon />
              </label>
            </>
          )}
        </div>
      </div>

      <div className={style.textBox}>
        <h2 className="heading-standard">
          <span>{user?.publicName}</span>
          {canEdit && allowEdit && (
            <button className={`bullet inverted darkblue ${style.editButton}`} onClick={() => setIsEdit(true)}>
              <EditIcon />
            </button>
          )}
        </h2>

        <p className={classNames('text-large', user?.description ? '' : style.placeholderText)}>
          {user?.description || (canEdit ? 'Beskriv dig själv': '')}
        </p>
      </div>

      <ul className={style.infolist}>
        {user?.answerEmail && (
          <li>
            <span>Svarsaddress</span>
            <a className="bold" href={`mailto:${user?.answerEmail}`}>{user?.answerEmail}</a>  {/*css!!!*/}
          </li>
        )}
        {user?.answerPhone && (
          <li>
            <span>Telefonnummer</span>
            <a className="bold" href={`tel:${user?.answerPhone}`}>{user?.answerPhone}</a> {/*css!!!*/}
          </li>
        )}
        {user?.place && (
          <li>
            <span>Från</span>
            <span className="bold">{user?.place.label}</span>
          </li>
        )}
        <li>
          <span> Medlem sedan</span>
          <span className="bold">{memberSince}</span>
        </li>
      </ul>
      {children && (
        <>
          <hr />
          {children}
        </>
      )}
    </Card>
  );
};

export { Profile };
